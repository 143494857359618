<!--
  placeholder colored square
-->

<template>
  <div class="placeholder" />
</template>

<style lang="scss" scoped>
.placeholder {
  width: 100%;
  aspect-ratio: 16 / 9;
  min-height: 100px;
  background: $light-gray;
  box-shadow: $shadow;
}
</style>
